import moment from 'moment';

export const nonAccentVietnamese = (str) => {
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
    return str;
};

export const formatterMoney =
    (format = true) =>
    (value) =>
        format ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : value;

export const parseMoney =
    (parse = true) =>
    (value) =>
        parse ? `${value}`.replaceAll(',', '') : value;
export const isImage = (url) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
};

export const dowloadFileFromUrl = (data, fileName) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
};

export const downloadCSVFromUrl = (data, fileName) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    var encodedUri = encodeURIComponent(data);
    link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodedUri);
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
};

export const isValidHttpUrl = (string) => {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
};

export const formatDate = (date = moment()) => {
    if (!date) {
        return '';
    }
    if (typeof date !== 'string' || !date) {
        return '';
    }
    if (!/^[\d/]+$/.test(date)) {
        return date;
    }
    const year = `${date || ''}`.substring(date.length - 2, date.length);
    const currentYear = moment().format('YY');

    if (date?.length === 4) {
        return `01/01/${date}`;
    }
    if (date?.length === 6) {
        const newYear = year > currentYear ? `19${year}` : `20${year}`;
        const month = date.substring(2, 4);
        const newDate = moment(`${date.substring(0, 4)}${newYear}`, 'DDMMYYYY');
        return parseInt(month, 10) > 12 ? date : newDate.format('DD/MM/YYYY');
    }
    if (date?.length === 8 && date.includes('/')) {
        const newYear = year > currentYear ? `19${year}` : `20${year}`;
        const month = date.substring(4, 6);
        return parseInt(month, 10) > 12
            ? date
            : moment(`${date.substring(0, 6)}/${newYear}`, 'DD/MM/YYYY').format('DD/MM/YYYY');
    }
    if (date?.length === 8) {
        const month = date.substring(2, 4);
        return parseInt(month, 10) > 12 ? date : moment(date, 'DDMMYYYY').format('DD/MM/YYYY');
    }
    if (date?.length === 10) {
        const month = date.substring(3, 5);
        return parseInt(month, 10) > 12 ? date : moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY');
    }
    if (date?.length <= 3 && parseInt(date, 10) < 200) {
        return moment().subtract(parseInt(date, 10), 'years').startOf('years').format('DD/MM/YYYY');
    }
    if (date?.length <= 3 && date >= '200') {
        return date;
    }
    return date;
};
